import React, { Component } from "react";
import { graphql } from "gatsby";
import MovLink from "../components/movlink";
// import { FaRegClock } from "react-icons/fa";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import Layout from "../layouts";
// import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
// import { rhythm } from "../utils/typography";
import {
  GridBoxContainer,
  GridHeader,
  GridBox,
  PageHeader,
  PaddedMobile,
  PageSpacing,
} from "../utils/styles";

class Home extends Component {
  render() {
    const data = this.props.data;

    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | Projects`}
          meta={[{ name: "description", content: "projects" }]}
        />
        <Flex
          mx={[-1, -1, -2]}
          flexWrap="wrap"
          css={[PageSpacing, PaddedMobile]}
        >
          <Box width={1} px={[1, 1, 2]} pb={4} key={`box-recent-projects`}>
            <h1 ref={this.myDivToFocus} css={PageHeader}>
              Projects
            </h1>
          </Box>
          {data.allWordpressPost.edges.map(({ node }) => (
            <Box
              width={[1 / 2, 1 / 2, 1 / 4]}
              p={[1, 1, 2]}
              key={`box-${node.slug}`}
              css={GridBoxContainer}
            >
              <div css={GridBox} key={node.slug}>
                <MovLink
                  to={`/${node.slug}`}
                  direction="left"
                  css={{ textDecoration: `none` }}
                >
                  {node.featured_media &&
                    node.featured_media.localFile &&
                    node.featured_media.localFile.childImageSharp && (
                      <Img
                        // css={{ marginBottom: rhythm(1 / 2) }}
                        key={
                          node.featured_media.localFile.childImageSharp.fluid
                            .src
                        }
                        fluid={
                          node.featured_media.localFile.childImageSharp.fluid
                        }
                      />
                    )}
                  <h3 css={GridHeader}>{node.title}</h3>
                </MovLink>
                {/* <div dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                {/* <PostIcons node={node} /> */}
              </div>
            </Box>
          ))}
        </Flex>
      </Layout>
    );
  }
}

export default Home;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allWordpressPost(
      filter: { wordpress_id: { ne: 2320 } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
